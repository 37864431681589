import { CircularProgress } from "@material-ui/core";
import LinearButton from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import "./index.css";
import { useHistory } from "react-router-dom";
import { API } from "../../../api";
import React, { useEffect, useState } from "react";

const ProgramModal = ({
  isEnrolled,
  id,
  name,
  description,
  image,
  type,
  onClose = () => {},
}) => {
  const history = useHistory();
  const [inProgress, toggleProgress] = useState(false);
  const [message, setMessage] = useState("");
  const [isUserEnrolled, setIsUserEnrolled] = useState(isEnrolled);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [isUGSubscribed, toggleUGSubscribed] = useState(Boolean);
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  useEffect(() => {
    async function fetchData() {
      toggleProgress(true);
      try {
        if (type === "sprint") {
          // If program type is sprint, no need for assessment questions
          toggleUGSubscribed(true);
          toggleProgress(false);
          return;
        }
        const { data, status } = await API.get(
          "strapi-assessment/assessment-responses",
          id
        );
        if (status === 200) {
          if (data.length === 0) {
            toggleUGSubscribed(false);
          } else {
            toggleUGSubscribed(true);
          }
        } else {
          toggleUGSubscribed(false);
        }
      } catch (err) {
        console.log(err);
      }
      toggleProgress(false);
    }
    fetchData();
  }, []);

  const getUserAssessment = async () => {
    toggleProgress(true);
    const { data, status } = await API.get(`user-data/assessment-response`, id);
    if (status === 200 && data.length === 0)
      history.push({
        pathname: `/assessment/questions/${id}`,
        state: {
          program_type: "program",
        },
      });
    else {
      history.push({
        pathname: `/assessment/questions/${id}`,
        state: {
          program_type: "program",
        },
      });
    }
  };

  const onEnroll = async (id) => {
    const tokens = localStorage.getItem("token");
    if (tokens) {
      try {
        toggleProgress(true);
        const { data, status, error } = await API.post(`program/enroll/${id}`);
        if (status === 200) {
          if (data.enrollment_date) {
            toggleProgress(false);
            if (type === "program") {
              history.push({
                pathname: `/assessment/questions/${id}`,
                state: {
                  program_type: "program",
                },
              });
            } else {
              // If program type is sprint, no need for assessment questions
              history.push({
                pathname: `/assessment/questions/${id}`,
                state: {
                  program_type: "sprint",
                },
              });
            }
          }
          toggleProgress(false);
        } else {
          if (data?.error?.code === "EnrollmentAlreadyExistsException") {
            setMessage("You are already enrolled in this program");
          } else if (data?.error?.code === "UpgradePlanToEnrollException") {
            setShowSubscriptionModal(true);
            setSubscriptionMessage(data?.error?.message);
          }

          toggleProgress(false);
        }
      } catch (err) {
        console.log(err);
        toggleProgress(false);
      }
    } else {
      history.push("/user/login");
    }
  };

  const body = (
    <div className="program-modal-body">
      <div
        style={{
          width: "320px",
          height: "190px",
          border: "1px solid #ccc",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <img
          src={image?.props?.src}
          alt={image?.props?.alt}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div className="body-medium-1 program-modal-desc">
        {isUserEnrolled
          ? isUGSubscribed
            ? "You are already enrolled in this program"
            : "Click here to complete your assessment"
          : description}
      </div>
    </div>
  );
  const footer = (
    <div className="program-modal-footer flex flex-col gap-4">
      {isUserEnrolled ? (
        isUGSubscribed ? (
          <LinearButton
            size="extra-large"
            text={inProgress ? <CircularProgress /> : "Already Enrolled"}
            disabled
          />
        ) : (
          <LinearButton
            size="extra-large"
            text={inProgress ? <CircularProgress /> : "Get Started"}
            disabled={inProgress}
            onClick={() => getUserAssessment()}
          />
        )
      ) : (
        <LinearButton
          text={inProgress ? <CircularProgress /> : "Enroll"}
          disabled={inProgress}
          onClick={() => onEnroll(id)}
        />
      )}

      {message}
    </div>
  );

  const upgradePlan = () => {
    history.push("/pricing");
  };
  return (
    <div>
      <NewModal heading={name} body={body} footer={footer} onClose={onClose} />
      {showSubscriptionModal && (
        <div className="hidden md:block">
          <NewModal
            heading="Subscription Status"
            body={
              <div className="mx-16 text-center">
                <h5 className="mb-8">{subscriptionMessage}</h5>
              </div>
            }
            footer={
              <div className="flex justify-around space-x-5 mt-8">
                <LinearButton text={"Upgrade plan"} onClick={upgradePlan} width={"10rem"}/>
              </div>
            }
            onClose={() => {
              setShowSubscriptionModal(false);
              setSubscriptionMessage("");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProgramModal;
