import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

import Home from "./views/Home";
import ProgramRoutes from "./views/Program/ProgramScreen";
import UserRoutes from "./views/User";
import AdminRoutes from "./views/Admin";
import ActivityRouter from "./views/Activity";
import GroupRouter from "./views/Group";
import ErrorPage from "./views/404RedirectPage";
import WIPPage from "./views/WIPPage";
import UserJournal from "./views/UserJournal";
import AssessmentRoutes from "./views/Assessment";
import UserProfileRoutes from "./views/UserProfile";
import ChallengeRoutes from "./views/Challenge";
import TeamRoutes from "./views/Team";
import Feedback from "./views/Feedback";
import Support from "./views/Support";
import ResponsiveHeader from "./components/Header";
import PrivateRoute from "./routers/PrivateRoute";
import PricingPage from "./views/Pricing";
import RevisionRouter from "./views/Revision";
import RunReport from "./views/Admin/Reports/RunReport";
import AdminHubRoutes from "./views/Administrator";
import GroupMembers from "./views/Administrator/Group/GroupMembers/GroupMembers";
import UserDetailsDashBoard from "./views/Administrator/User/UserDetails/UserDetails";
import SubscriptionRoutes from "./views/Subscription";

const Routes = () => {
  return (
    <Router>
      <ResponsiveHeader />
      <Switch>
        <Route exact path="/" component={Home}>
          <Redirect to="/user/dashboard" />
        </Route>
        <Route exact path="/programs/" component={ProgramRoutes} />
        <Route path="/admin" component={AdminRoutes} />
        <Route path="/group/" component={GroupRouter} />
        <Route path="/user/" component={UserRoutes} />
        {/* <Route path="/pricing" component={WIPPage} /> */}
        <Route path="/contact" component={WIPPage} />
        <Route path="/about" component={WIPPage} />
        <Route exact path="/pricing" component={PricingPage} />
        <PrivateRoute path="/assessment" component={AssessmentRoutes} />
        <PrivateRoute path="/team" component={TeamRoutes} />
        <PrivateRoute path="/journal" component={UserJournal} />
        <PrivateRoute path="/profile" component={UserProfileRoutes} />
        <PrivateRoute path="/activity" component={ActivityRouter} />
        <PrivateRoute path="/revision" component={RevisionRouter} />
        <PrivateRoute path="/challenge" component={ChallengeRoutes} />
        <PrivateRoute path="/feedback" component={Feedback} />
        <PrivateRoute path="/support" component={Support} />
        <PrivateRoute path="/display-report" component={RunReport} />
        <PrivateRoute path="/administrator" component={AdminHubRoutes} />
        <PrivateRoute path="/group-members" component={GroupMembers} />
        <Route path="/subscription" component={SubscriptionRoutes} />
        <Route component={ErrorPage} />
      </Switch>
    </Router>
  );
};

export default Routes;
