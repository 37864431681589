import React from "react";
import { API } from "../../../api";
import LinearButton from "../../../components/Button";
import LinearTextBox from "../../../components/TextBox";

const AdminClearData = () => {
  const clearData = async () => {
    await API.post("admin/clear-data", {
      email: "armaansaini77+900@gmail.com",
    });
  };

  return (
    <div className="flex flex-col justify-center items-center">
      Clear data for demo account? <p>demo@linear.live</p>
      <LinearButton onClick={clearData} text="Clear" />
    </div>
  );
};

export default AdminClearData;
