import React, { useEffect, useState } from "react";
import feedback_star_fill from "../../../assests/icons/feedback_star_fill.svg";
import drill from "../../../assests/icons/drill.svg";
import daily_challenge from "../../../assests/icons/daily_challenge.svg";
import day_challenge from "../../../assests/icons/day_challenge.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ChallengeReviewDownloadIcon from "@mui/icons-material/Download";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import { Tooltip } from "@mui/material";
import axios from "axios";

import { Document, Packer, Paragraph, TextRun, ImageRun } from "docx";
import { saveAs } from "file-saver";

import "./index.css";
import useSWR from "swr";
import Paginator from "../../../components/Paginator";
import { API } from "../../../api";

const PAGE_SIZE = 5;
const UserChallenge = () => {
  const { data: challengeList } = useSWR("user-data/completed-challenges");
  // const [challengeList, setChallengeList] = useState();
  const [challengeAccordion, setChallengeAccordion] = useState([]);
  const [lowerRange, setLowerRange] = useState(0);
  const [upperRange, setUpperRange] = useState(PAGE_SIZE);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [message, setMessage] = useState("");

  useEffect(() => {
    // async function getchallenges() {
    // const { data } = await API.get("user-data", "completed-challenges");
    // setChallengeList(data);
    setChallengeAccordion(Array(challengeList?.length).fill(false));
    // }
    // getchallenges();
  }, [challengeList]);

  const downloadAndGenerateReviewDocument = async (challenge_id, goal_id) => {
    // Check if goal_id or challenge_id is missing
    if (!goal_id || !challenge_id) {
      setOpenSnackBar(true);
      setMessage("Goal Id or Challenge Id missing");
      return;
    }

    console.log(goal_id, challenge_id);

    try {
      // Fetch data from the API
      const { data, status } = await API.get(
        `challenge/review/${goal_id}/${challenge_id}`
      );

      const docContent = [];
      for (const item of data.review) {
        if (item?.text) {
          docContent.push(
            new Paragraph({
              children: [new TextRun({ text: item.text, size: 24, break: 1 })],
              spacing: { after: 300 },
            })
          );
        }

        if (item?.question) {
          docContent.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: `Question: ${item.question}`,
                  bold: true,
                  size: 24,
                }),
              ],
            })
          );

          docContent.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: `User Response: ${item.user_response}`,
                  size: 24,
                }),
              ],
              spacing: { after: 300 },
            })
          );
        }
      }

      const doc = new Document({
        sections: [
          {
            children: docContent,
          },
        ],
      });

      Packer.toBlob(doc).then((blob) => {
        console.log(blob);
        saveAs(blob, "example.docx");
        console.log("Document created successfully");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getChallengeReview = (challenge_id, goal_id) => {
    return (
      <div>
        <button
          onClick={() =>
            downloadAndGenerateReviewDocument(challenge_id, goal_id)
          }
          className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer"
        >
          <Tooltip title="Download Review Document" arrow placement="top">
            <svg
              className="w-6 h-6 -mt-2"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <ChallengeReviewDownloadIcon />
            </svg>
          </Tooltip>
        </button>
      </div>
    );
  };

  return (
    <div
      className="flex flex-col items-center"
      style={{
        overflowY: "scroll",
        scrollBehavior: "smooth",
        maxHeight: "70vh",
      }}
    >
      <div>
        <Paginator
          setUpperRange={setUpperRange}
          setLowerRange={setLowerRange}
          pageContents={PAGE_SIZE}
          totalLength={challengeList?.length}
        />
      </div>
      <div className="self-stretch">
        {challengeList
          ?.slice(lowerRange, upperRange)
          ?.map((challenge, index) => {
            return (
              <div key={index} className="mx-4 border-b-2 py-4">
                <div
                  className="flex flex-row justify-between items-center flex-wrap mb-4"
                  onClick={() => {
                    const temp = [...challengeAccordion];
                    temp[index] = !temp[index];
                    setChallengeAccordion([...temp]);
                  }}
                >
                  <div className="flex flex-row items-center justify-center gap-4">
                    <img
                      alt="challenge_type"
                      src={
                        challenge.challenge?.type === "daily"
                          ? day_challenge
                          : challenge.challenge?.type === "challenge"
                          ? daily_challenge
                          : drill
                      }
                      style={{ width: "1.25rem" }}
                    />
                    <h5>{challenge?.challenge?.name}</h5>
                    <div className="flex flex-row justify-end">
                      {challengeAccordion[index] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    {/* {challengeAccordion[index] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )} */}
                    <div className="ml-10 body-medium-1 mt-5 sm:mt-0 sm:body-extra-small-1">
                      {new Date(challenge.completed_date).getDate()} /{" "}
                      {new Date(challenge.completed_date).getMonth() + 1} /{" "}
                      {new Date(challenge.completed_date).getFullYear()}
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-row gap-2 flex-wrap challenge-container"
                  style={{
                    display: challengeAccordion[index] ? "flex" : "none",
                  }}
                >
                  <div className="flex flex-row items-center gap-2">
                    {challenge.user_response}
                    <img
                      alt="feedback_star"
                      src={feedback_star_fill}
                      style={{ width: "1rem" }}
                    />
                  </div>
                  <div
                    className="overflow-hidden body-medium-1"
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {challenge.user_feedback}
                  </div>
                  <div className="mt-2">
                    {getChallengeReview(
                      challenge.challenge_id,
                      challenge.goal_id
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackBar(false)}
        TransitionComponent={`SlideTransition`}
      >
        <Alert
          severity={"info"}
          sx={{
            width: "100%",
            // color: "white",
            // "& .MuiAlert-icon": {
            //   color: "white",
            // },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserChallenge;
