import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import LinearButton from "../../../components/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { validateEmail } from "../../../lib/helpers";
import { API } from "../../../api";
import Table from "../../../components/Table";
import { CircularProgress } from "@material-ui/core";

const UserSearch = () => {
  const [user_email, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [showErrorBox, setShowErrorBox] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [userLevelInformation, setUserLevelInformation] = useState([]);
  const [userLevelEnrollment, setUserLevelEnrollment] = useState([]);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const _handleChange = (event) => {
    const { value } = event.target;
    setUserEmail(value);
    // Basic email validation
    setError(!validateEmail(value));
  };

  const getProgressBar = (progress) => {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" value={progress} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const onSearch = async () => {
    setErrorMessage("");
    setShowErrorBox(false);
    setIsLoading(true);
    setUserLevelEnrollment([]);
    setUserLevelInformation([]);

    //Validating Email
    if (validateEmail(user_email)) {
      //   console.log("Search for email:", email);
      setError(false);
    } else {
      setError(true);
      return;
    }

    try {
      const { data, status } = await API.get(
        `reports/org-admin/user-level-information`,
        user_email
      );
      if (status === 200) {
        setUserLevelInformation(data.userInformation);
        setUserLevelEnrollment(data.userProgramCompletion);
      } else {
        setShowErrorBox(true);
        setErrorMessage(data.error ? data.error.message : "Error Occurred");
      }
    } catch (error) {
      console.error("Failed to fetch user schedule", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userLevelEnrollment.length === 0) return;
    const tableData = [];
    for (const enrollment of userLevelEnrollment) {
      const programName = enrollment?.program;
      const status = enrollment?.status;
      const daysCompleted = enrollment?.days_completed;
      const programCompletionStatus = getProgressBar(
        Number(enrollment?.completion_percentage)
      );
      const element = [
        programName,
        status,
        daysCompleted,
        programCompletionStatus,
      ];
      tableData.push(element);
    }
    setTableHeader(["Program", "Status", "Days Completed", "Completion"]);
    setTableBody(tableData);
  }, [userLevelInformation, userLevelEnrollment]);

  return (
    <div>
      <header>
        <div className="flex flex-row space-x-2">
          <div>
            <TextField
              id="outlined-basic"
              label="Enter User Email"
              variant="outlined"
              name="email"
              value={user_email}
              onChange={_handleChange}
              error={error}
              helperText={error ? "Please enter a valid email address" : ""}
              margin="dense"
            />
          </div>
          <div className="mt-4">
            <LinearButton onClick={onSearch} width="8rem" text="Search" />
          </div>
        </div>
      </header>

      {showErrorBox && (
        <div class="flex justify-center items-start min-h-screen bg-white mt-12">
          <div class="text-center p-8 bg-white-200 rounded-t-lg shadow-md w-full md:w-1/2">
            <p class="text-3xl font-bold text-gray-500 mb-4">{errorMessage}</p>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress size={80} />
        </div>
      ) : userLevelInformation.length > 0 ? (
        <>
          <main>
            <div className="flex flex-col mt-8 divide-y-2">
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Name:</div>
                <div className="w-4/6 font-bold">
                  {userLevelInformation[0]?.name
                    ? userLevelInformation[0]?.name
                    : "No name"}
                </div>
              </div>
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Registered:</div>
                <div className="w-4/6 font-bold">
                  {userLevelInformation[0]?.registered ? "Yes" : "No"}
                </div>
              </div>
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Active Status:</div>
                <div className="w-4/6 font-bold">
                  {userLevelInformation[0]?.registered
                    ? userLevelInformation[0]?.status
                    : "-"}
                </div>
              </div>
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Last Active Date:</div>
                <div className="w-4/6 font-bold">
                  {userLevelInformation[0]?.last_active_date
                    ? userLevelInformation[0]?.last_active_date.slice(0, 10)
                    : "-"}
                </div>
              </div>
            </div>
          </main>

          {userLevelEnrollment ? (
            <footer>
              <div className="mt-4">
                <Table header={tableHeader} body={tableBody} />
              </div>
            </footer>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default UserSearch;
