import React, { useState, useEffect } from "react";
import NewModal from "../../NewModal";
import LinearButton from "../../Button";
import { API } from "../../../api";
import { useHistory } from "react-router-dom";

const SubscriptionModal = () => {
  const [showTrialRestrictionModal, setShowTrialRestrictionModal] =
    useState(false);
  const [showSkipButton, setShowSkipButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const [message, setMessage] = useState("");
  const history = useHistory();

  const upgradePlan = () => {
    history.push("/pricing");
  };

  const getUserSubscriptionData = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await API.get(
        "subscription",
        "user-subscription"
      );
      if (status === 200) {
        setSubscriptionData(data);
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserSubscriptionData();
  }, []);

  useEffect(() => {
    // Set openUpdateModal to true if userSubscriptionData has both added_on and expires_on
    if (subscriptionData?.added_on && subscriptionData?.expires_on) {
      var addedOnDate = new Date(subscriptionData.added_on);
      var expiresOnDate = new Date(subscriptionData.expires_on);

      var currentDate = new Date();

      var currentDay =
        Math.floor((currentDate - addedOnDate) / (1000 * 60 * 60 * 24)) + 1;
      var daysLimit =
        subscriptionData?.orgSubscription.subscriptionType?.no_of_days;

      var planType =
        subscriptionData?.orgSubscription.subscriptionType?.subscription_type;

      var daysLeft = daysLimit - currentDay;

      /**if current day has exceeded the days limit we will not
       * check anything simply just block the user access.
       * */
      if (currentDay > daysLimit) {
        setShowTrialRestrictionModal(true);
        setShowSkipButton(false);
        setMessage(
          "Your one-month free plan has expired. Please upgrade your plan to continue learning."
        );
        return;
      } else {
        //if less than 5 days are left than give them alert
        if (daysLeft <= 5) {
          setShowTrialRestrictionModal(true);
          setMessage(
            `Your free plan is almost up. You've got ${daysLeft} days left. Please upgrade to keep learning.`
          );
          return;
        }
        //if current day is under the day limit
        if (currentDay % 5 === 0 && planType === "free_tier") {
          setShowTrialRestrictionModal(true);
          setMessage("You are on one month free tier plan.");
          return;
        }
      }
    } else {
      setShowTrialRestrictionModal(false);
    }
  }, [subscriptionData]);

  return (
    <div>
      {isLoading && null}
      {showTrialRestrictionModal && (
        <div className="hidden md:block">
          <NewModal
            heading="Subscription Status"
            body={
              <div className="mx-16 text-center">
                <h5 className="mb-8">{message}</h5>
              </div>
            }
            footer={
              <div className="flex justify-around space-x-5 mt-8">
                {showSkipButton && (
                  <LinearButton
                    text={"Skip for now"}
                    width={"8rem"}
                    onClick={() => setShowTrialRestrictionModal(false)}
                  />
                )}
                <LinearButton
                  text={"Upgrade plan"}
                  onClick={upgradePlan}
                  width={"8rem"}
                />
              </div>
            }
            hasCloseButton={false}
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionModal;
