import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getAuth } from "../../lib/auth";

export default function PrivateRoute({ ...props }) {
  const isAuthenticated = getAuth();
  return (
    <Route {...props}>
      {!isAuthenticated && <Redirect to="/user/login" />}
    </Route>
  );
}
