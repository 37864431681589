import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ErrorPage from "../404RedirectPage";
import ProfileDashboard from "./ProfileDashboard";

const UserProfileRoutes = () => {
  const history = useHistory();
  useEffect(() => {
    const expiry = localStorage.getItem("expiry");
    const now = new Date();
    const token = localStorage.getItem("token");
    if (!token || !expiry || new Date(expiry).getTime() <= now.getTime()) {
      history.replace("/user/login");
    }
  }, []);

  return (
    <Switch>
      <Route exact path="/profile/" component={ProfileDashboard} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default UserProfileRoutes;
