import React, { useState } from "react";
const TourScreen = () => {
  const [showModal, setShowModal] = useState(true);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              maxWidth: "900px",
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.4)",
            }}
          >
            <button
              onClick={toggleModal}
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                backgroundColor: "#f5f6f7",
                color: "#6e7175",
                border: "1px solid #d1d1d1",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="#6e7175"
              >
                <path d="M7.416 6L11.7 1.716A1 1 0 0 0 10.284.3L6 4.584 1.716.3A1 1 0 1 0 .3 1.716L4.584 6 .3 10.284a1 1 0 1 0 1.414 1.414L6 7.416l4.284 4.284a1 1 0 0 0 1.414-1.414L7.416 6z" />
              </svg>
            </button>

            <iframe
              src="https://demo.arcade.software/32gABI3zI56vKpNjDAu4?embed"
              frameborder="0"
              loading="lazy"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              style={{ width: "100%", height: "550px", border: "none" }}
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default TourScreen;
