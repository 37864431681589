const getAuth = () => {
  const expiry = localStorage.getItem("expiry");
  const token = localStorage.getItem("token");
  const now = new Date();
  const now_utc = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
  if (new Date(expiry).getTime() <= now.getTime() || !token || !expiry) {
    return false;
  }
  return true;
};

export { getAuth };
