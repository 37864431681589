import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../404RedirectPage";
import Customer from "./Customer";
import SubscriptionDetails from "./Details";
import Card from "./Card";
import Confirmation from "./Confirmation/Confirmation";

const SubscriptionRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/subscription/details"
        component={SubscriptionDetails}
      />
      <Route exact path="/subscription/confirmation" component={Confirmation} />
      <Route exact path="/subscription/customer" component={Customer} />
      <Route exact path="/subscription/card" component={Card} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default SubscriptionRoutes;
