import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@material-ui/core";
import LinearButton from "../../../components/Button";
import { useHistory } from "react-router-dom";
import { API } from "../../../api";
import NewModal from "../../../components/NewModal";

const Confirmation = () => {
  const history = useHistory();
  const handleBack = () => {
    history.push("/pricing");
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isButtonProgress, setIsButtonProgress] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Get the current date
    const startDate = new Date();
    // Calculate the date that is 365 days from now
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 365);

    // Format the dates as mm-dd-yyyy
    const formatDate = (date) =>
      date
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");

    setStartDate(formatDate(startDate));
    setEndDate(formatDate(endDate));
  }, []);

  const confirmSubscription = async () => {
    setIsButtonProgress(true);
    try {
      const { status } = await API.post("support/confirm-subscription", {
        number_of_user: 1,
        subscription_type: 2,
        subscription_start_date: startDate,
        subscription_end_date: endDate,
      });
      setShowConfirmationModal(true);
      if (status === 200) {
        setMessage(
          "Thank you for confirming the upgrade to the premium plan. We will shortly email you with a payment link to complete the payment. Once you complete the payment, your premium subscription will be enabled."
        );
      }
    } catch (error) {
      setMessage(error?.message);
    }
    setIsButtonProgress(false);
  };

  const handleClose = () => {
    history.push("/user/dashboard");
  };
  return (
    <div className="min-h-content" style={{ height: "100vh" }}>
      <div
        className="w-full h-full"
        style={{ background: "var(--background)" }}
      >
        {/* <h4 className="flex justify-start mx-4">Confirmation</h4> */}
        <div
          className="overflow-auto mx-4 rounded-xl p-4 shadow-md mt-4"
          style={{ background: "var(--white)" }}
        >
          <header>
            <h4>Confirmation</h4>
          </header>

          <main>
            <div className="flex flex-col mt-8 divide-y-2">
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Total Number Of Users</div>
                <div className="w-4/6 font-bold">1</div>
              </div>

              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Plan Selected</div>
                <div className="w-4/6 font-bold">Annually</div>
              </div>
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Plan Total Amount</div>
                <div className="w-4/6 font-bold">INR 2500</div>
              </div>
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Plan Start Date</div>
                <div className="w-4/6 font-bold">{startDate}</div>
              </div>
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Plan End Date</div>
                <div className="w-4/6 font-bold">{endDate}</div>
              </div>
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Next Billing Date</div>
                <div className="w-4/6 font-bold">{endDate}</div>
              </div>
              <div className="flex flex-row h-12 items-center">
                <div className="w-2/6">Amount To Be Paid</div>
                <div className="w-4/6 font-bold">INR 2500</div>
              </div>
              {/* Promo Code */}
              {/* <div className="flex flex-row justify-start h-12 items-center">
                <Checkbox />
                <div className="body-small-1">Do You Have Promo Code?</div>
              </div> */}
            </div>
          </main>

          <footer>
            <div className="flex flex-row justify-between mt-8">
              <div>
                <LinearButton text="Back" onClick={handleBack} />
              </div>
              <div>
                <LinearButton
                  text={
                    isButtonProgress ? <CircularProgress size={30} /> : "Submit"
                  }
                  onClick={confirmSubscription}
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
      {showConfirmationModal && (
        <div className="hidden md:block">
          <NewModal
            heading="Subscription Confirmation"
            body={
              <div className="mx-16 text-center">
                <p className="mb-8">{message}</p>
              </div>
            }
            // footer={
            //   <div className="flex justify-around space-x-5 mt-8">
            //     <LinearButton
            //       text={"Upgrade plan"}
            //     />
            //   </div>
            // }
            onClose={() => {
              handleClose();
              setShowConfirmationModal(false);
              setMessage("");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Confirmation;
